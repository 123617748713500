import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button, Modal, Autocomplete } from "@mui/material"
import BitMgmt from "../utils/fetch/bitmgmt/bitmgmt";
import { useForm } from 'react-hook-form'
import Spinner from "../atoms/spinner/spinner";

const ChangeLocationModal = (props) => {
    const {account, modalOpen, onClose, onSubmit} = props
    const bigMgmtAPI = new BitMgmt()
    const { handleSubmit } = useForm();

    const [loading, setLoading] = useState(false)
    const [availablePortfolios, setAvailablePortfolios] = useState([])
    const [newPortfolioId, setNewPortfolioId] = useState('')
    const [newPortfolioName, setNewPortfolioName] = useState('')

    const submitPortfolioChange = () => {
        /* Autocomplete wasn't playing nice with React Hook Form,
        so we set the data to state variables and manually gather it here */
        let submissionData = {
            currentPortfolio: account.location_portfolio_id,
            newPortfolio: newPortfolioId,
            newPortfolioName: newPortfolioName
        }
        onSubmit(submissionData)
        onClose();
    }

    useEffect(() => {
        const loadPortfolioChangeData = async () => {
            if(!account.account_id){
                return false;
            }
            setLoading(true)
            const portfolioList = await bigMgmtAPI.getOrganizationByLocationPortfolioId(account.location_portfolio_id)
            setAvailablePortfolios(portfolioList)
            setLoading(false)
            return true;
        }
        loadPortfolioChangeData();
    }, [account.account_id])

    return (
        <Modal open={modalOpen} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                minWidth: {xs: "400px", md: "600px"},
                bgcolor: '#333333',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
                color: "#fff",
                zIndex: 100
            }}>
                <Typography variant="h4" sx={{marginBottom: "16px"}}>{account.full_name}</Typography>
                <Box>
                    <Box sx={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                        <Typography variant="h5" sx={{fontWeight: "bold"}}>Account:</Typography>&nbsp;&nbsp;
                        <Typography variant="h5">{account.account_id}</Typography>
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                        <Typography variant="h5" sx={{fontWeight: "bold"}}>Current Portfolio:</Typography>&nbsp;&nbsp;
                        <Typography variant="h5">{account.portfolio_name}</Typography>
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                        <Typography variant="h5" sx={{fontWeight: "bold"}}>New Portfolio:</Typography>&nbsp;&nbsp;
                        { loading ?
                            <Spinner/> :
                            <Autocomplete
                                options={availablePortfolios}
                                getOptionLabel={(option) => option.location_portfolio_name || "" }
                                autoComplete
                                renderInput={(params) => {
                                    return (<TextField {...params} label="Available Portfolios" sx={{width: "225%"}} />)}}
                                onChange={(event, value) => {
                                    if (value !== null && value.location_portfolio_id){
                                        setNewPortfolioId(value.location_portfolio_id)
                                        setNewPortfolioName(value.location_portfolio_name)
                                    }
                                }}
                                size="small"
                            />
                        }
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    paddingBottom: "16px",
                    paddingTop: "16px"
                }}>
                    <Button
                        disabled={newPortfolioId === ''}
                        variant="contained"
                        onClick={handleSubmit(submitPortfolioChange)}
                        sx={{width: "45%"}}
                    >Update</Button>
                    <Button
                        variant="contained"
                        onClick={onClose}
                        sx={{width: "45%"}}
                    >Cancel</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default ChangeLocationModal