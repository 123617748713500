import React from "react";
import styles from "./webApp.module.css";
import {  useDispatch } from "react-redux";
import doLogout from '../../utils/fetch/auth/logout';
import {ThemeProvider, Container} from '@mui/material'
import theme from "../../theme/theme";
import {
  setUserSessionIsAuthenticated
} from '../../reducers/userSessionSlice';
import AppContainer from "../../organisms/AppContainer";
import CssBaseline from '@mui/material/CssBaseline'

export default (props) => {
  const dispatch = useDispatch();

  const logOutApp = async () => {
    const appLogout = await doLogout();
    if(appLogout.success === true) {
      dispatch(setUserSessionIsAuthenticated(false));
    }
  };

  return (
      <ThemeProvider theme={theme}>
        <div className={styles.web_app}>
          <CssBaseline />
          <AppContainer setLoginInfo={() => logOutApp()} />/>
            <Container sx={{marginTop: {sm: "8vh", md: "8vh", xl: "3vh"}, minWidth: "85%", minHeight: "calc(90% - 6vh)"}}>
                {props.children}
            </Container>
        </div>
      </ThemeProvider>
  );
};
