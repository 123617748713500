import {
    Drawer,
    Box,
    AppBar,
    Toolbar,
    ThemeProvider,
    Button,
    List,
    ListItem,
    Typography,
    Divider,
    ListItemIcon,
    Link,
} from "@mui/material"

import {Menu} from "react-feather"
import { toggleSidePanel} from "../reducers/appSettingsSlice";
import {useDispatch, useSelector} from "react-redux";
import theme from "../theme/theme"
import DynamicIcon from "../molecules/dynamicIcons";
import React from "react";


/*Function pulled from https://github.com/uidotdev/usehooks/ with much gratitude*/
export function useWindowSize() {
  const [size, setSize] = React.useState({
    width: null,
    height: null,
  });

  React.useLayoutEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return size;
}


/* Any edits to the list of sections/pages should be made by editing navItems */
const navItems = [
    {sectionName: "CRM", contents: [
            {name: "Contacts", url: "/contacts/all", icon: "Users", active: "contacts"},
            {name: "New Contact", url: "/contacts/create", icon: "UserPlus", active: "new_contact"}
        ]},
    {sectionName: "CLOS",
        contents: [
            {name: "Server Monitor", url: "/serverMonitor", icon: "Server", active: "server_monitor"},
            {name: "Query Tool", url: "/querytool", icon: "Search", active: "query_tool"},
            {name: "Active Duplicates", url: "/activeDuplicates", icon: "Copy", active: "active_duplicates"},
            {name: "Active Dupes Files", url: "/activeDuplicates/files", icon: "FileText", active: "active_duplicates_files"},
            {name: "Portfolio File Parse", url: "/portfolioFile", icon: "Table", active: "portfolio_file"}
        ],
    },
    {sectionName: "Bit Management",
        contents: [
            {name: "Organizations", url: "/organizations", icon: "Briefcase", active: "organizations"},
            {name: "Portfolios", url: "/portfolios", icon: "Archive", active: "portfolios"},
            {name: "Locations", url: "/locations", icon: "Globe", active: "bit_locations"},
            {name: "Location Portfolios", url: "/locationPortfolios", icon: "Layers", active: "location_portfolios"},
            {name: "Account Search", url: "/accounts", icon: "Search", active: "account_search"}
        ],
    },
    {sectionName: "Admin",
        contents: [
            {name: "Users", url: "/users", icon: "Users", active: "blckline_users"}
        ],
    },
    {sectionName: "Account",
        contents: [
            {name: "Notification Service", url: "/notificationServices", icon: "Bell", active: "notification_services"},
            {name: "My Account", url: "/myAccount", icon: "User", active: "my_account"},
            {name: "Sign Out", url: "/", icon: "LogOut", active: ""}
        ],
    }
]

const AppContainer = (props) => {
    const { setLoginInfo } = props;
    const collapsed = useSelector((state) => state.AppSettings.sidePanelToggle);
    const activePage = useSelector((state) => state.AppSettings.ActivePage);
    const dispatch = useDispatch()
    const drawerWidth = 235;
    const isMobile = useWindowSize().width < 600

    const userSessionPermissions = useSelector(
        (state) => state.UserSessions.permissions
    );

    const sectionSnippet = (section) => {
        return (
            <Box key={section.sectionName} sx={{display: "flex", flexDirection: "column"}}>
                <Box>
                    <ListItem key={section.sectionName + "ListItem"} sx={{marginTop: "8px", backgroundColor: "primary.main"}}>
                        <Typography><strong>{section.sectionName}</strong></Typography>
                    </ListItem>
                </Box>
                <Box sx={{display: "flex", flexDirection: "column", minHeight: "100%"}}>
                    <Divider sx={{backgroundColor: "primary.main"}}/>
                    <List>
                        <Box style={{display: "flex", flexDirection: "column"}}>
                            {section.contents.map(item => {
                                return (
                                    <ListItem
                                        key={item.name}
                                        onClick={item.name === "Sign Out" ? () => setLoginInfo(false) : () => dispatch(toggleSidePanel())}
                                        sx={{backgroundColor: item.active === activePage ? "secondary.dark" : "primary.main"}}
                                    >
                                        <ListItemIcon sx={{color: "primary.contrastText" }}>
                                            <DynamicIcon name={item.icon ? item.icon : "Globe"} iconSize={14}/>
                                        </ListItemIcon>
                                        {item.name !== "Sign Out" && (
                                            <Link
                                                href={item.url}
                                                sx={{color: "primary.contrastText", textDecoration: "none"}}
                                                variant={"body2"}
                                            >
                                                {item.name}
                                            </Link>)
                                        }
                                        {item.name === "Sign Out" && (
                                            <Typography sx={{cursor: "pointer", color: "primary.contrastText"}} variant={"body2"}>{item.name}</Typography>
                                        )}
                                    </ListItem>
                                )
                            })}
                        </Box>
                    </List>
                </Box>
            </Box>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <AppBar component="nav" sx={{zIndex: theme.zIndex.drawer + 1}}>
                <Toolbar sx={{backgroundColor: "primary.main", maxHeight: {sm: "4vh", md: "4vh"}}}>
                    <Button
                        sx={{color: "primary.contrastText", display: !isMobile ? "none" : "block"}}
                        onClick={() => dispatch(toggleSidePanel())}
                    >
                        <Menu size={20}/>
                    </Button>
                    {isMobile && (
                        <img src="/blackline_b.svg" alt="mobile-logo" style={{width: "20px", marginLeft: "40px"}}/>
                    )}
                    {!isMobile && (
                        <img src="/blackline.svg" alt="logo" style={{width: "120px", marginLeft: "30px"}}/>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{backgroundColor: "primary.main", width: drawerWidth}}
                open={collapsed}
                variant={isMobile ? "temporary" : "permanent"}
                onClose={(ev, reason) => dispatch(toggleSidePanel())} /*This attribute enforces a close event when clicking outside the nav*/
            >
                <Box sx={{backgroundColor: "primary.main", minHeight: "100%", paddingTop: "2vh" }}>
                    <List sx={{marginTop: "4vh"}}>
                        {navItems.map(section => {
                            /* Permissions are governed at the section level, if this should govern individual options, we will have to overhaul this */
                            if ((section.sectionName === "CRM" && userSessionPermissions.CRM) ||
                                (section.sectionName === `CLOS` && userSessionPermissions.CLOS) ||
                                (section.sectionName === "Bit Management" && userSessionPermissions.BitMGMT) ||
                                (section.sectionName === "Admin" && userSessionPermissions.Admin) ||
                                (section.sectionName === "Account")
                            ){
                                return (<>{sectionSnippet(section)}</>)
                            }
                        })}
                    </List>
                </Box>
            </Drawer>
        </ThemeProvider>
    )
}

export default AppContainer;