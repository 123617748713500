import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux";
import {Box, Typography, Card, CardHeader} from "@mui/material"
import {setActivePage} from '../../reducers/appSettingsSlice'
import Spinner from "../../atoms/spinner/spinner";
import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

export const Account = (props) => {
    const { match } = props
    const { params: { accountId }} = match

    const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
    const bitMgmtAPI = new BitMgmt();
    const dispatch = useDispatch();

    const retrievalParams = {
        full_name: '',
        ssn: '',
        client_reference_id: '',
        account_id: accountId,
    }

    const [fetching, setFetching] = useState(false)
    const [accountDetails, setAccountDetails] = useState({})

    useEffect(() => {
        if (ActivePage !== "view_account") {
            dispatch(setActivePage("view_account"));
        }
        pullAccountData(accountId);
    }, [accountId]);

    const pullAccountData = async () => {
        setFetching(true)
        const accountRetrieval = await bitMgmtAPI.doAccountSearch(retrievalParams)
        /* Endpoint returns an array of objects, even though we only expect one result, so set state var to first item*/
        setAccountDetails(accountRetrieval[0]);
        setFetching(false)
    }

    return (
        <Box sx={{ marginTop: {xs: "8vh", md: "4vh"}}}>
            {fetching? (
                <Spinner/>
            ) : (
                <Box >
                    <Card sx={{padding: "8px"}}>
                        <CardHeader title={`${accountDetails.full_name} | ID:  ${accountDetails.account_id}`} />
                        <Box sx={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                            <Typography sx={{fontWeight: "bold"}}></Typography>
                            <Typography></Typography>
                        </Box>
                        <Typography variant="h5">Debt History</Typography>
                        <Box sx={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                            <Typography sx={{fontWeight: "bold"}}>Original Client:&nbsp;</Typography>
                            <Typography>{accountDetails.original_client}</Typography>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                            <Typography sx={{fontWeight: "bold"}}>Debt Description:&nbsp;</Typography>
                            <Typography>{accountDetails.debt_description}</Typography>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                            <Typography sx={{fontWeight: "bold"}}>Original Principal Amount:&nbsp;</Typography>
                            <Typography>${accountDetails.original_principal_amount}</Typography>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                            <Typography sx={{fontWeight: "bold"}}>Interest Rate:&nbsp;</Typography>
                            <Typography>{accountDetails.interest_rate}%</Typography>
                        </Box>
                         <Typography variant="h5">Organization and Portfolio Information</Typography>
                        <Box sx={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                            <Typography sx={{fontWeight: "bold"}}>Organization Name:&nbsp;</Typography>
                            <Typography>{accountDetails.organization_name}</Typography>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                            <Typography sx={{fontWeight: "bold"}}>Location Portfolio Name:&nbsp;</Typography>
                            <Typography>{accountDetails.location_portfolio_name}</Typography>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "row", marginBottom: "8px"}}>
                            <Typography sx={{fontWeight: "bold"}}>Portfolio Name:&nbsp;</Typography>
                            <Typography>{accountDetails.portfolio_name}</Typography>
                        </Box>

                    </Card>
                </Box>
            )}
        </Box>
    )
}

export default Account;