import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import FCQueryAPI from "../../utils/fetch/queryTool/queryTool";
import doExport from '../../utils/exports/fileExport';
import { Box, Card, CardHeader, Button, Checkbox } from "@mui/material"

import "prismjs/components/prism-sql";
import "./editorStyles.css";

import {
  fc_molecules,
  fc_atoms
} from '../../uiSrc';

import TabView from '../../templates/tabView/UpdatedTabView'; //this will eventually be in ui again and no longer need to be imported here
import AuthRequest from "./authRequest";

import {
  setActivePage
} from '../../reducers/appSettingsSlice';

import userSettings from "../../utils/userSettings/userSettings";



import {
  setQueryLocationIsRunning,
  setQueryLocationError,
  setQueryLocationResults,
  setQueryisRunning,
  setQueryLocationIsComplete,
  setQueryText,
  toggleQueryLocation,
  setQueryToolNumRows,
  combineQueryResults,
  setPageNum,
  sortQueryResults,
  setTablePaginated,
  setQueryAuthCode,
  setQueryNeedsAuth,
  setQueryToolTab,
  setQueryLocations,
} from '../../reducers/queryToolSlice';



export const QueryTool = () => {
  const {
    SortableColumnsTable,
  } = fc_molecules;

  const {
    Spinner,
    Button,
    BlockDataItem,
    CheckableRightDisplay,
  } = fc_atoms;

  const queryAPI = new FCQueryAPI();

  const dispatch = useDispatch();
  
  const ActivePage = useSelector((state) => state.ActivePage);

  // Indicates whether the locations list is being pulled
  const fetchingServers = useSelector((state) => state.QueryTool.meta.fetchingServers);
  // All locations available to query

  const locationList = useSelector((state) => state.QueryTool.locationList);

  // Start table state variables

  const hasPaginated = useSelector((state) => state.QueryTool.meta.hasPaginated);
  const tableSortDir = useSelector((state) => state.QueryTool.meta.sortDir);
  const tableSortKey = useSelector((state) => state.QueryTool.meta.sortKey);
  const numRowsResults = useSelector((state) => state.QueryTool.meta.numRows);
  const pageNumResults = useSelector((state) => state.QueryTool.meta.pageNum);
  const numPagesResults = useSelector((state) => state.QueryTool.meta.numPages);
  const activeTab = useSelector((state) => state.QueryTool.meta.activeTab);
  // End table state variables

  // Auth code selectors
  const queryAuthCode = useSelector((state) => state.QueryTool.authCode.code);
  const queryAuthCodeExpires = useSelector((state) => state.QueryTool.authCode.expires);
  const queryNeedsAuth = useSelector((state) => state.QueryTool.remoteQuery.queryNeedsAuth);
  const queryIsRunning = useSelector((state) => state.QueryTool.remoteQuery.isRunning);
  const queryTextValue = useSelector((state) => state.QueryTool.remoteQuery.text);
  const remoteQueryInstance = useSelector((state) => state.QueryTool.remoteQuery);
  const queryLocations = useSelector((state) => state.QueryTool.remoteQuery.locations);
  const queryResultsAll = useSelector((state) => state.QueryTool.remoteQuery.paginatedResults);
  const rightPaneStatus = useSelector((state) => state.QueryTool.remoteQuery.rightPaneStatus);
  const allQueryResults = useSelector((state) => state.QueryTool.remoteQuery.results);

  const setQueryResults = (location, results, error) => {
    if (error) {
      dispatch(setQueryLocationError({ location, error }));
      dispatch(setQueryLocationResults({ location, results: [] }));
    } else {
      dispatch(setQueryLocationResults({ location, results }));
    }
    dispatch(setQueryLocationIsRunning({ location: location, running: false }));
    dispatch(setQueryLocationIsComplete({ location, isComplete: true }));

    const otherQueriesRunning = Object.keys(remoteQueryInstance.locations)
      .map((i) => remoteQueryInstance.locations[i])
      .reduce((p, c) => {
        if (p) {
          return true;
        } else {
          return c.isRunning;
        }
      }, false);
    if (otherQueriesRunning === false) {
      dispatch(setQueryisRunning(false));
      dispatch(combineQueryResults(true));
      dispatch(setTablePaginated(true));
      dispatch(setQueryToolTab(2));
    }
  };
  const runQuery = (location, text) => {
    // Do fetch
    queryAPI.runRemoteQuery(location, text, queryAuthCode, setQueryResults);
  };
  const runQueries = () => {
    if (!queryIsRunning) {
      dispatch(setQueryisRunning(true));
    }
    const { locations, text } = remoteQueryInstance;
    const location_regex = /\[use_locations\:[^\]]+\]/g;
    const cleanText = text.replace(location_regex, "");
    Object.keys(locations).forEach((l) => {
      dispatch(setQueryLocationIsRunning({ location: l, running: true }));
      runQuery(l, cleanText);
    });
  };

  const selectAllLocations = () => {
    const locations = [...locationList];
    locations.forEach((l) => {
      if (!queryLocations[l.short_name]) {
        dispatch(toggleQueryLocation(l.short_name));
      }
    });
  };

  const deSelectAllLocations = () => {
    const locations = [...locationList];
    locations.forEach((l) => {
      if (queryLocations[l.short_name]) {
        dispatch(toggleQueryLocation(l.short_name));
      }
    });
  };

  const updateQueryAuthCode = (authCode) => {
    dispatch(setQueryAuthCode(authCode));
    if (authCode.length > 19) {
      queryAPI.checkQueryAuthTokenTimeout(authCode, setQueryAuthCodeExpires);
    }
  };

  const setQueryAuthCodeExpires = (expires) => {
    dispatch(setQueryAuthCodeExpires(expires));
  };

  const setQueryLocationList = () => {
    queryAPI.getSpokeQueryLocations((ll) =>
      dispatch(setQueryLocations(ll))
    );
  };

  const doesQueryRequireAuth = (queryText) => {
    const lowercaseQuery = queryText.toLowerCase();
    const isDanger =
      lowercaseQuery.match(/update\s+/i) ||
      lowercaseQuery.match(/delete/i) ||
      lowercaseQuery.match(/drop/i) ||
      lowercaseQuery.match(/truncate/i) ||
      lowercaseQuery.match(/insert/i) ||
      lowercaseQuery.match(/alter/i) ||
      lowercaseQuery.match(/create\s+/i) ||
      lowercaseQuery.match(/rename/i) ||
      lowercaseQuery.match(/attach/i) ||
      lowercaseQuery.match(/restore/i);
    if (isDanger && !(queryAuthCode && queryAuthCode.length)) {
      dispatch(setQueryNeedsAuth(true));
    } else {
      dispatch(setQueryNeedsAuth(false));
    }
  };

  const preprocessQueryText = (queryText) => {
    // [use_locations:ALA,EXT,SAA,TXT,BYB]
    const location_regex = /\[use_locations\:[^\]]+\]/g;
    const location_string_start_index = queryText.search(location_regex);
    if (location_string_start_index > -1) {
      const location_string_match = queryText.match(location_regex);
      const location_array = location_string_match[0]
        .replace("[use_locations:", "")
        .replace("]", "")
        .split(",");
      if (location_array.length) {
        deSelectAllLocations();
        location_array.forEach((i) => {
          const upperI = i.toUpperCase();
          if (
            locationList.find(
              (location) => location.short_name.toUpperCase() === upperI
            )
          ) {
            dispatch(toggleQueryLocation(String(upperI)))
          }
        });
      }
    }
    dispatch(setQueryText(queryText));
  };

  useEffect(() => {
    if (ActivePage !== "query_tool") {
      dispatch(setActivePage("query_tool"));
      if (!locationList.length) {
        setQueryLocationList();
      }
    }
  }, [ActivePage, locationList]);
  return (
      <Box sx={{ marginTop: {xs: "8vh", md: "4vh"}}}>
        <Box sx={{display: "grid", gridTemplateColumns: "1fr 3fr", gridColumnGap: "16px", gridAutoRows: "minmax(min-content, max-content)", gridRowGap: "16px" }}>
          <Card sx={{padding: "8px", color: "#fff"}}>
            <CardHeader title="Locations"/>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
              <Button
                  sx={{fontSize: 14}}
                  onClick={() => selectAllLocations()}
                  disabled={queryIsRunning}
              >
                Select All
              </Button>
              <Button
                  disabled={queryIsRunning}
                  color="red"
                  style={{marginRight: 10}}
                  onClick={() => deSelectAllLocations()}
              >
                Clear
              </Button>
            </Box>
            {fetchingServers ? (
                <div
                    className="inline_center"
                    style={{width: "calc(10% - 20px)"}}
                >
                  <Spinner/>
                </div>
            ) : (
                <div>
                  <BlockDataItem style={{marginBottom: 0}}/>
                  <div
                      style={{
                        maxHeight: "calc(100vh - 370px)",
                        background: "var(--darker)",
                        padding: 10,
                        width: "calc(100% - 40px)",
                        overflowY: "scroll",
                      }}
                  >
                    {!locationList.length ? (
                        <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                        >
                          <Spinner/>
                        </div>
                    ) : (
                        locationList.map((loc) =>
                                queryLocations[loc.short_name] &&
                                queryLocations[loc.short_name].isRunning ? (
                                    <div
                                        key={loc.short_name}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          marginBottom: "5px",
                                        }}
                                    >
                        <span style={{fontSize: "13px"}}>
                          {loc.short_name.toUpperCase()}
                        </span>
                                      <Spinner/>
                                    </div>
                                ) : (
                                    <CheckableRightDisplay
                                        title={loc.short_name.toUpperCase()}
                                        key={loc.short_name}
                                        onClick={() => dispatch(toggleQueryLocation(loc.short_name))}
                                        checked={queryLocations[loc.short_name]}
                                        rightLabel={
                                          queryLocations[loc.short_name] ? (
                                              queryLocations[loc.short_name].error ? (
                                                  <span
                                                      title={`${queryLocations[loc.short_name].error}`}
                                                  >
                                Error
                              </span>
                                              ) : queryLocations[loc.short_name].results &&
                                              queryLocations[loc.short_name].results.length ? (
                                                  queryLocations[loc.short_name].results.length
                                              ) : queryLocations[loc.short_name].isComplete ? (
                                                  0
                                              ) : (
                                                  ` `
                                              )
                                          ) : (
                                              ``
                                          )
                                        }
                                    />
                                )
                        )
                    )}
                  </div>
                </div>
            )}
          </Card>
          <Card sx={{padding: "8px", color: "#fff"}}>
          <CardHeader title="Query" />
          <Box>
            { allQueryResults && allQueryResults.length ?
                <>
                  <Button style={{marginRight: 10}}
                          onClick={() => {
                            const headers = {
                              server: 'SERVER',
                              ...Object.keys(allQueryResults[0])
                                  .filter((i) => i !== 'server')
                                  .reduce((p,c) => {
                                    const tmp = {...p};
                                    tmp[c] = c.toUpperCase()
                                    return tmp;
                                  }, {})
                            };
                            doExport([headers, ...allQueryResults], 'csv')
                          }}>CSV</Button>
                  <Button
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        const headers = {
                          server: 'SERVER',
                          ...Object.keys(allQueryResults[0])
                              .filter((i) => i !== 'server')
                              .reduce((p,c) => {
                                const tmp =  {...p};;
                                tmp[c] = c.toUpperCase()
                                return tmp;
                              }, {})
                        };
                        doExport([headers, ...allQueryResults], 'xlsx')
                      }}
                  >XLS</Button>
                </> : null}
          </Box>
          <TabView
            activeItem={activeTab}
            setActiveItem={(index) => {
              dispatch(setQueryToolTab(index));
            }}
            headers={[
              {title: 'Editor'},
              {title: 'Results'},
              {title: 'Both'},
            ]}
          >
            <div
              style={{
                width: "100%",
                flexDirection: "column",
              }}
            >
              <div
                style={
                  rightPaneStatus === "results" ? { display: "none" } : {}
                }
              >
              {/* Show if the active tab is 'Editor' or 'Both' - KS 11/13/21 */}
              { activeTab === 0 || activeTab === 2 ?
                <div>
                  <Editor
                    value={queryTextValue}
                    onValueChange={(code) => {
                      preprocessQueryText(code);
                      doesQueryRequireAuth(code);
                    }}
                    highlight={(code) => highlight(code, languages.sql)}
                    padding={10}
                    style={{
                      width: "100%",
                      height: "300px",
                      backgroundColor: "#2e2e2e",
                      color: "#fff",
                    }}
                  />
                  <br />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <AuthRequest
                      setAuthCode={updateQueryAuthCode}
                      queryNeedsAuth={queryNeedsAuth}
                      authExpires={queryAuthCodeExpires}
                    />
                    <div
                      style={{
                        width: "calc(30% - 20px)",
                        textAlign: "right",
                      }}
                    >
                      <Button
                        disabled={
                          queryIsRunning ||
                          !Object.keys(queryLocations).length
                        }
                        onClick={() => runQueries()}
                        style={{ marginRight: 10 }}
                      >
                        Run Query
                      </Button>
                    </div>
                  </div>
                </div>
              : null }
              {/* Show if the active tab is 'Results' or 'Both' - KS 11/13/21 */}
              { activeTab === 1 || activeTab === 2 ?
                <SortableColumnsTable
                  centeredHeaders={[]}
                  defaultFullHeight={true}
                  data={queryResultsAll}
                  sortDir={tableSortDir}
                  sortKey={tableSortKey}
                  keyColumn={`server`}
                  numRows={numRowsResults}
                  numPages={numPagesResults}
                  pageNum={pageNumResults}
                  paginate={true}
                  hasPaginated={hasPaginated}
                  hideButtons={false}
                  setPaginated={() =>
                    dispatch(setTablePaginated(true))
                  }
                  setNumRows={(n) => {
                    dispatch(setQueryToolNumRows(n));
                    userSettings.setNewUserSetting(numRowsResults, {
                      module: "QUERYTOOL",
                      type: "SET_QUERY_TOOL_NUM_ROWS",
                      payload: n,
                    });
                  }}
                  setPageNum={(n) =>
                    dispatch(setPageNum(n))
                  }
                  onSort={(key) => {
                    dispatch(sortQueryResults(key));
                  }}
                /> 
              : null }
              </div>
            </div>
          </TabView>
        </Card>
      </Box>
    </Box>
  );
};

export default QueryTool;