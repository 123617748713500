import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ArrowLeft, ArrowRight } from "react-feather";
import FCSpokesAPI from '../../utils/fetch/spokes/spokes';
import {
  Box,
  Card,
  CardHeader,
  Button,
  Snackbar,
  Alert,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel
} from "@mui/material"

import {
  setActivePage
} from '../../reducers/appSettingsSlice';

import {
  fc_atoms
} from '../../uiSrc';

import { 
  setNotificationPrefs,
  setLocationList,
  setNotificationsPrefsToAdd,
  setSelectedTypes,
  setSpokesToRemove,
  setSubbedSpokes,
  removeSubbedSpokes,
} from "../../reducers/notificationsSlice";

export const NotificationServices =  () => {

  const {
    Spinner,
  } = fc_atoms;

  const spokesAPI = new FCSpokesAPI();

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');
  const [successSnackBarVisible, setSuccessSnackBarVisible] = useState(false)
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const locationList = useSelector((state) => state.NotificationPrefs.locationList);
  const notificationPrefs = useSelector((state) => state.NotificationPrefs.prefs);
  const locToAdd = useSelector((state) => state.NotificationPrefs.prefs.toAdd);
  const locToRemove = useSelector((state) => state.NotificationPrefs.prefs.toRemove);

  const {
    subbedSpokes,
    subbedTypes,
    unSubbedSpokes,
    digest
  } = notificationPrefs;

  const loadInitialState = async () => {
    if (!locationList.length) {
      setLoading(true);
      const locations = await spokesAPI.getSpokes();
      dispatch(setLocationList(locations));
    }
    const info = await spokesAPI.getUserNotificationSettings();
    //filter the location list for all unsubscribed spokes
    const unsubbed = [];
    locationList.map((i) => {
      if(!info.spokes.includes(i.location_id)){unsubbed.push(i.location_id)}
    });
    dispatch(setNotificationPrefs({
      digest: info.digest,
      subbedTypes: info.types,
      subbedSpokes: info.spokes,
      toAdd: [],
      toRemove: [],
      unSubbedSpokes: unsubbed,
      locationSubStatus: [],
    }));
    console.log(info);
    setLoading(false);
  };

  //gets the initial settings from API
  useEffect(() => {
    loadInitialState();
    dispatch(setActivePage("notification_services"));
  },[]);

  //hardcoding array here until they're pulled through API KS 12/16/21
  const serviceTypeArray = [
    {type_id: 4, type_name: 'Server offline'},
    {type_id: 1, type_name: 'CLOS offline'},
    {type_id: 2, type_name: 'MySQL offline'},
    {type_id: 3, type_name: 'Sybase offline'},
    {type_id: 5, type_name: 'System EOL soon'},
    {type_id: 6, type_name: 'Kill Switch Engaged'},
  ];

  const handleSnackbarClose = () => {
    setSuccessSnackBarVisible(false)
  }
  
  const saveNotificationPreferences = async () => {
    setSubmitting(true);
    //getting just the type_ids
    const serviceTypeValueKeys = serviceTypeArray.reduce((acc,c) => {acc.push(c.type_id);return acc;},[]);
    // getting the types that aren't selected
    const unsubbedTypes = serviceTypeValueKeys.filter((type) => !subbedTypes.includes(type));
    await spokesAPI.updateNotificationPreferences(subbedSpokes,subbedTypes,digest,unSubbedSpokes,unsubbedTypes)
      .then((res) => {
        if(res.success) {
          console.log(res)
          setSuccessSnackBarVisible(true)
          setSubmitting(false);
        } else if (res.success === false) {
          console.log("ERROR")
        }
      });
  };

  return (
    <Box sx={{ marginTop: {xs: "8vh", md: "4vh"}}}>
        <Card sx={{color: "#fff", padding: "8px"}}>
          <CardHeader title="Notification Service" />
          {!loading ? (
            <>
              <Box sx={{
                display: {xs: "flex", md: "grid"},
                gridTemplateColumns: {xs: null, md: "30% 70%"},
                flexDirection: "column",
                overflowY: "auto"
              }}>
                <Box sx={{ width: 400 }}>
                  <Typography variant="h6">Daily Digest</Typography>
                  <Typography variant="caption">Receive a daily email containing the status of all locations</Typography>
                 <Box sx={{paddingTop: "16px", paddingBottom: "16px"}}>
                  <FormGroup>
                    <FormControlLabel control={
                      <Checkbox
                          checked={digest}
                          onClick={() => {
                            const newPrefs = {...notificationPrefs};
                            newPrefs.digest = !digest;
                            dispatch(setNotificationPrefs(newPrefs));
                          }}
                      />
                    } label="Daily Digest"/>
                  </FormGroup>
                 </Box>
                  <hr
                    style={{
                      margin: "auto",
                      marginBottom: 20,
                    }}
                  />
                  <Typography variant="h6">Event Notifications</Typography>
                  <Typography variant="caption">Receive an email when one of the following events occurs</Typography>
                  <Box sx={{paddingTop: "16px", paddingBottom: "16px"}}>
                    <FormGroup>
                      {
                        serviceTypeArray.length ? serviceTypeArray.map((i) => {
                          return(
                              <FormControlLabel control={
                                <Checkbox
                                    checked={subbedTypes && subbedTypes.includes(i.type_id) ? true : false}
                                    onClick={() => {
                                      if(subbedTypes.includes(i.type_id)){
                                        const types = [...subbedTypes];
                                        types.splice(types.indexOf(i.type_id), 1);
                                        dispatch(setSelectedTypes(types));
                                      } else {
                                        dispatch(setSelectedTypes([...subbedTypes, i.type_id]));
                                      }
                                    }}
                                />
                              } label={i.type_name}/>
                            )
                          })
                        : null
                      }
                    </FormGroup>
                  </Box>
                </Box>
                <Box>
                  <Typography variant="h6">Server Subscription</Typography>
                  <Typography
                      variant="caption"
                      sx={{marginBottom: "8px"}}
                  >Servers for which you will receive event emails</Typography>
                  <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "20px"}}>
                    <Box style={{ width: "calc(100% - 40px)", marginRight: 30 }}>
                      <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: {xs: "column", md: "row"},
                      }}>
                        <Typography variant="h6" sx={{color: "#cb544f"}}>Not Subscribed</Typography>
                        <Button
                          variant="contained"
                          onClick={() => {
                            let toAdd = [];
                            if (!locToAdd.length) {
                              locationList.map((el) => {
                                if (
                                    !subbedSpokes.includes(el.location_id)
                                ) {
                                  toAdd.push(el.location_id);
                                }
                                return null;
                              });
                            }
                            dispatch(setNotificationsPrefsToAdd(toAdd));
                          }}
                        >{locToAdd.length ? "Deselect All" : "Select All"}</Button>
                      </Box>
                      <div
                        style={{
                          height: 350,
                          background: "var(--darker)",
                          padding: 10,
                          width: "100%",
                          overflowY: "scroll",
                          marginTop: 5,
                        }}
                      >
                        <FormGroup>
                          {locationList.map((el) => {
                            if (!subbedSpokes.includes(el.location_id)) {
                              return (
                                  <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={locToAdd.includes(el.location_id)}
                                          onClick={() => {
                                            const toAdd = [...locToAdd];
                                            if (toAdd.includes(el.location_id)) {
                                              toAdd.splice(toAdd.indexOf(el.location_id), 1);
                                            } else {
                                              toAdd.push(el.location_id);
                                            }
                                            dispatch(setNotificationsPrefsToAdd(toAdd));
                                          }}
                                        />
                                      }
                                  label={el.short_name.toUpperCase()} />
                              );
                            }
                          })}
                        </FormGroup>
                      </div>
                    </Box>
                    <Box
                      style={{
                        height: 350,
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        flexDirection: "column",
                        marginRight: "8px"
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{ marginBottom: 10 }}
                        onClick={() => dispatch(setSubbedSpokes([...subbedSpokes, ...locToAdd]))}
                      >
                        Add
                        <ArrowRight
                          className="icon"
                        />
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => dispatch(removeSubbedSpokes(locToRemove))}
                      >
                        <ArrowLeft
                          className="icon"
                        />
                        Remove
                      </Button>
                    </Box>
                    <Box style={{ minWidth: "60%", marginLeft: "10px" }}>
                      <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: {xs: "column", md: "row" }
                          }}>
                        <Typography variant="h6" sx={{color: "#65b084"}}>Subscribed</Typography>
                        <Button
                            sx={{fontSize: "14", color: "#fff"}}
                            variant={"contained"}
                            onClick={() => {
                              let toRemove = [];
                              if (!locToRemove.length) {
                                locationList.map((el) => {
                                  if (
                                      subbedSpokes.includes(el.location_id)
                                  ) {
                                    toRemove.push(el.location_id);
                                  }
                                  return null;
                                });
                              }
                              dispatch(setSpokesToRemove(toRemove));
                            }}
                        >{locToRemove.length ? "Deselect All" : "Select All"}</Button>
                      </Box>
                      <Box
                        style={{
                          height: 350,
                          background: "var(--darker)",
                          padding: 10,
                          minWidth: "100%",
                          overflowY: "scroll",
                          marginTop: 5,
                        }}
                      >
                        <FormGroup>
                          { locationList.map((el) => {
                            if (subbedSpokes.includes(el.location_id)) {
                              return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={locToRemove.includes(el.location_id)}
                                        onClick={() => {
                                          let toRemove = [...locToRemove];
                                          if (toRemove.includes(el.location_id)) {
                                            toRemove.splice(toRemove.indexOf(el.location_id), 1);
                                          } else {
                                            toRemove.push(el.location_id);
                                          }
                                          dispatch(setSpokesToRemove(toRemove));
                                        }}
                                      />
                                    }
                                    label={el.short_name.toUpperCase()}
                                  />
                              );
                            }
                            return null;
                          })}
                        </FormGroup>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{display: "flex", justifyContent: "center", width: "100%"}}>
                <Button
                    variant="contained"
                    onClick={() => saveNotificationPreferences()}
                    disabled={submitting}
                    style={{paddingLeft: "60px", paddingRight: "60px"}}
                >
                  {submitting ? <Spinner /> : "Save"}
                </Button>
              </Box>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                width: "calc(100% - 20px)",
                minHeight: "300px",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </div>
          )}
          <Snackbar
              open={successSnackBarVisible}
              autoHideDuration={5000}
              onClose={handleSnackbarClose}
          >
            <Alert
                onClose={handleSnackbarClose}
                severity={"success"}
                variant={"filled"}
                sx={{width: "100%"}}
            >
              Changes Made Successfully!
            </Alert>
          </Snackbar>
        </Card>
    </Box>
  );
};

export default NotificationServices;