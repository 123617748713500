import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Card, CardHeader, TextField, FormGroup, Button, CardContent, CardActions } from "@mui/material"
import { useForm } from 'react-hook-form'
import { cardActionStyles } from "../../theme/theme";

import {
  setActivePage
} from '../../reducers/appSettingsSlice';

import CRMPutters from "../../utils/fetch/crm/crmPutters";
import CenteredSpinner from "../../organisms/CenteredSpinner";

export const CreateContact = (props) => {
  const [fetching, setFetching] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);

  const userSessionPermissions = useSelector((state) => state.UserSessions.permissions);


  useEffect(() => {
    if (ActivePage !== "new_contact") {
      dispatch(setActivePage("new_contact"));
    }
  });

  const onSubmit = async (data) => {
    if (userSessionPermissions.CRM !== 2) {
      alert(
        "Access denied. Please contact an administrator to request access to this feature."
      );
      return;
    }
    const crmPutters = new CRMPutters();
    setFetching(true);
    const ok = await crmPutters.addContact(data);
    if (ok) {
      setFetching(false);
      props.history.push(`/contacts/contact/${ok.id}`);
    } else {
      alert("An error occurred. Could not add contact.");
      setFetching(false);
    }
  };

  /*The RegEx patterns in the below form allow for punctuation (as we didn't want to force an error for 'Mr.')*/

  return (
      <Box sx={{ marginTop: {xs: "8vh", md: "4vh"}}}>
        {fetching ? <CenteredSpinner /> :
        <Card
          sx={{ width: "100%", backgroundColor: "primary.main", color: "primary.contrastText" }}
        >
          <CardHeader
            title="Add Contact"
            sx={{backgroundColor: "#333333"}}
          >
          </CardHeader>
          <CardContent sx={{display: "flex", justifyContent: "center"}}>
            <FormGroup sx={{width: "80%"}}>
              <TextField
                  {...register("honorific", {pattern: /\p{L}+/u})}
                  sx={{margin: '5px'}}
                  name="honorific"
                  label="Honorific"
                  error={(errors?.honorific) ? true : false}
                  helperText={(errors?.honorific) ? "Please enter a valid honorific" : ""}
              />
              <TextField
                  {...register("first", {required: true, pattern: /\p{L}+/u})}
                  sx={{margin: '5px'}}
                  name="first"
                  label="First"
                  required
                  error={(errors?.first) ? true : false}
                  helperText={(errors?.first) ? "Please enter a valid first name" : ""}
              />
              <TextField
                  {...register("middle", {pattern: {value: /\p{L}+/u}})}
                  sx={{margin: '5px'}}
                  name="middle"
                  label="Middle"
                  error={(errors?.middle) ? true : false}
                  helperText={(errors?.middle) ? "Please enter a valid middle name" : ""}
              />
              <TextField
                  {...register("last", {required: true, pattern: /\p{L}+/u})}
                  sx={{margin: '5px'}}
                  name="last"
                  label="Last"
                  required
                  error={(errors?.last) ? true : false}
                  helperText={(errors?.last) ? "Please enter a valid last name" : ""}
              />
              <TextField
                  {...register("suffix", {pattern: /\p{L}+/u})}
                  sx={{margin: '5px'}}
                  name="suffix"
                  label="Suffix"
                  error={(errors?.suffix) ? true : false}
                  helperText={(errors?.suffix) ? "Please enter a valid suffix" : ""}
              />
              <CardActions sx={{cardActionStyles}}>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{width: "40%"}}
                    onClick={handleSubmit(onSubmit)}> Save
                </Button>
              </CardActions>
            </FormGroup>
          </CardContent>
        </Card>
        }
      </Box>
  );
};

export default CreateContact;