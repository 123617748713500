import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import { Link } from "react-router-dom";
import {Box, Typography, TextField, CardHeader, Card, Button, Snackbar, Alert} from "@mui/material"
import MuiDataTable from "mui-datatables"
import ChangeLocationModal from "../../organisms/MuiChangeLocationPortfolioModal";

import {
  setSearchFilters,
} from "../../reducers/accountSlice";

// API Interface
import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

import {
  fc_atoms
} from '../../uiSrc';


import {
  setActivePage
} from '../../reducers/appSettingsSlice';

export const Accounts = () => {

  const {
    Spinner,
  } = fc_atoms;

  // Inital App Config
  const dispatch = useDispatch();
  const bitMgmtAPI = new BitMgmt();


  // Redux Selectors
  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  const BitMgmtAccountFilters = useSelector((state) => state.AccountSearch.filters);

  // Page State
  const [accountSearchResults, setAccountSearchResults] = useState([]);
  const [searchIsRunning, setSearchIsRunning] = useState(false);
  const [showChangeLocationPortfolio, setShowChangeLocationPortfolio] = useState(false);
  const [accountToEdit, setAccountToEdit] = useState({});
  const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false)
  const [errorSnackBarOpen, setErrorSnackBarOpen] = useState(false)
  const [successSnackBarText, setSuccessSnackBarText] = useState("")
  const [errorSnackBarText, setErrorSnackBarText] = useState("")

  const dataTableColumns = [
    {name: "account_id", label: "Account ID", options: {sort: true, filter: true}},
    {name: "full_name", label: "Full Name", options: {sort: true, filter: true}},
    {
      name: "ssn", label: "SSN", options: {
        sort: true,
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let rawSSN = accountSearchResults[dataIndex].ssn
          let hiddenSSN = rawSSN.toString().substring(5).padStart(8, "*---")
          return (
            <div className={`accounts_num_holder`} style={{cursor: 'pointer'}}>
              <span className={`masked_first`}>{hiddenSSN}</span>
              <span className={`unmasked_first`}>{rawSSN}</span>
            </div>
          )
        }
      }
    },
    {name: "client_reference_id", label: "Client Reference ID", options: {sort: true, filter: true}},
    {name: "original_client", label: "Original Client", options: {sort: true, filter: true}},
    {name: "debt_description", label: "Debt Description", options: {sort: true, filter: true}},
    {name: "original_principal_amount", label: "Original Principal Amt", options: {sort: true, filter: true}},
    {name: "opened_date", label: "Opened Date", options: {sort: true, filter: true}},
    {name: "charge_off_date", label: "Charge Off Date", options: {sort: true, filter: true}},
    {name: "interest_rate", label: "Interest Rate", options: {sort: true, filter: true}},
    {
      name: "loaded", label: "Loaded", options: {
        sort: true,
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          let value = accountSearchResults[dataIndex].loaded
          if (value === 0) {
            return "No"
          } else {
            return "Yes"
          }
        }
      }
    },
    {name: "portfolio_name", label: "Portfolio Name", options: {sort: true, filter: true}},
    {name: "organization_name", label: "Organization Name", options: {sort: true, filter: true}},
    {name: "location_portfolio_name", label: "Location Portfolio Name", options: {sort: true, filter: true}},
    {name: "", label: "", options: {
      sort: false,
      filter: false,
      customBodyRenderLite: (dataIndex) => {
        let account = accountSearchResults[dataIndex]
        return (
          <Box sx={{display: "flex", flexDirection: {sm: "column", md: "row"}}}>
            <Link to={`/accounts/account/${account.account_id}`}>
              <Button
                variant="contained"
                sx={{fontSize: "11px", marginRight: "4px", height: "100%", backgroundColor: "#212121"}}
              >
                View
              </Button>
            </Link>
            <Button
              variant="contained"
              onClick={() => {
                openChangeLocationModal(account)
              }}
              sx={{fontSize: "11px", marginRight: "4px", backgroundColor: "#212121"}}
            >
              Reassign Portfolio
            </Button>
            <Button
                onClick={() => {
                  doPushToLocation(account, dataIndex)
                }}
                disabled={(account.loaded === true)}
                sx={{backgroundColor: "#212121", cursor: `${account.loaded  === true ? 'not-allowed' : 'pointer'}`, fontSize: "11px" }}
                variant="contained"
            >
              Push to Location
            </Button>
          </Box>
        )
      }
    },
    }
  ]

  const runAccountSearch = async () => {
    setSearchIsRunning(true);
    const accountSearch = await bitMgmtAPI.doAccountSearch(BitMgmtAccountFilters);
    setAccountSearchResults(accountSearch);
    setSearchIsRunning(false);
  }

  const updateFilterValue = (key, val) => {
    const tmpFilters = {...BitMgmtAccountFilters};
    tmpFilters[key] = val;
    dispatch(setSearchFilters(tmpFilters))
  }

  const openChangeLocationModal = async (account) => {
    // If account is already loaded, this action cannot be completed
    if(account.loaded) {
      setErrorSnackBarText("Account has already been loaded.")
      setErrorSnackBarOpen(true)
      return false;
    }
    // Populate the state variable with account details for modal
    setAccountToEdit({...account});
    setShowChangeLocationPortfolio(true);
  };

  const reassignLocationPortfolio = async (locPortfolioData) => {
    if (locPortfolioData){
      const updateLocationPortfolio = await bitMgmtAPI.updateAccountPortfolioLocation(locPortfolioData.newPortfolio, accountToEdit.account_id)
      if (updateLocationPortfolio.success){
        setSuccessSnackBarText(`Successfully transferred account to portfolio ${locPortfolioData.newPortfolioName}`)
        setSuccessSnackBarOpen(true)
        runAccountSearch()
        setAccountToEdit({})
      }
    } else {
      setErrorSnackBarText("Portfolio invalid. Contact administrator for resolution.")
      setErrorSnackBarOpen(true)
      setAccountToEdit({})
    }
  }

  const updateAccountsArray = (newAccountData, index) => {
    const resultsArray = [...accountSearchResults];
    resultsArray[index] = {...newAccountData};
    setAccountSearchResults(resultsArray);
  };

  const doPushToLocation = async (account, index) => {
    const tmpAccount = {...account};

    // If account is already loaded, return error
    if(account.loaded) {
      setErrorSnackBarText("Account has already been loaded.")
      setErrorSnackBarOpen(true)
      return false;
    }
    const results = {success: false};
    const confirmAccount = window.confirm(
        `Push account ${tmpAccount.account_id} to location: ${tmpAccount.location_portfolio_name} (ID: ${tmpAccount.location_portfolio_id})?`
    );
    if(confirmAccount) {
      const updateCall =  await bitMgmtAPI.doPostToLocation(tmpAccount.account_id);
      if(updateCall.success || updateCall.length) {
        results.success = true;
      }
      // Pass the account to the API. It will get the location in the API, so we aren't passing it from the front-end.
    }
    if (results.success) {
      tmpAccount.loaded = true;
      updateAccountsArray(tmpAccount, index);
      setSuccessSnackBarText(`Account successfully pushed to location ${tmpAccount.location_portfolio_id}`)
      setSuccessSnackBarOpen(true)
    }
  };

  // const doChangeWorklist = (account) => {
  //   // 
  //   alert(`Change worklist on account: ${JSON.stringify(account)}`);
  // };

  useEffect(() => {
    if (ActivePage !== "account_search") {
      dispatch(setActivePage("account_search"));
    }
  });

  const handleSnackbarClose = () => {
    setSuccessSnackBarOpen(false)
    setErrorSnackBarOpen(false)
    setErrorSnackBarText(" ")
    setSuccessSnackBarText(" ")
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 13) {
      runAccountSearch()
    }
  };

  /* TODO: set fixed height for main body card, convert MUI Data Table to scroll instead*/
  return (
    <Box sx={{ marginTop: {xs: "8vh", md: "4vh"}}}>
      <Card
        sx={{minHeight: "calc(100vh - 150px)", padding: "8px", color: "#fff"}}
      >
        <CardHeader title={"Account Search"}/>
        <Box sx={{marginBottom: "8px", display: "flex", flexDirection: "row"}}>
          <TextField
            name="fullName"
            label="Full Name"
            onChange={(event) => updateFilterValue('full_name', event.target.value)}
            onKeyDown={handleKeyDown}
            sx={{marginRight: "8px"}}
            size="small"
          />
          <TextField
            name="ssn"
            label="SSN"
            onChange={(event) => updateFilterValue('ssn', event.target.value)}
            onKeyDown={handleKeyDown}
            sx={{marginRight: "8px"}}
            size="small"
          />
          <TextField
            name="clientReferenceId"
            label="Client Reference ID"
            onChange={(event) => updateFilterValue("client_reference_id", event.target.value)}
            onKeyDown={handleKeyDown}
            sx={{marginRight: "8px"}}
            size="small"
          />
          <TextField
            name="accountId"
            label="Account ID"
            onChange={(event) => updateFilterValue("account_id", event.target.value)}
            onKeyDown={handleKeyDown}
            sx={{marginRight: "8px"}}
            size="small"
          />
        </Box>
        {(
          searchIsRunning ?
            <div
              style={{
                width: "100%",
                height: 300,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner/>
            </div>

            :
            accountSearchResults.length ? (
              <MuiDataTable
                data={accountSearchResults}
                columns={dataTableColumns}
                options={{
                  filterType: "textField",
                  selectableRows: "none",
                  responsive: "standard",
                  print: false,
                  download: false
                }}
              />
            ) : (
              <Box>
                <Typography>No matching accounts</Typography>
              </Box>
            )
        )}
      </Card>
      <ChangeLocationModal
        modalOpen={showChangeLocationPortfolio}
        onClose={() => {
          setAccountToEdit({})
          setShowChangeLocationPortfolio(false)
        }}
        account={accountToEdit}
        onSubmit={(data) => reassignLocationPortfolio(data)}
      />
      <Snackbar
        open={successSnackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={"success"}
          variant={"filled"}
          sx={{width: "100%"}}
        >
          {successSnackBarText}
        </Alert>
      </Snackbar>
      <Snackbar
        open={errorSnackBarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={"error"}
          variant={"filled"}
          sx={{width: "100%"}}
        >
          {errorSnackBarText}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Accounts;