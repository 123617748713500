// Pages
import Organizations from './pages/organizations/Organizations';
import Portfolios from './pages/portfolios/Portfolios';
import Locations from './pages/locations/Locations';
import LocationPortfolios from './pages/locationPortfolios/LocationPortfolios';
import Accounts from './pages/accounts/Accounts';
import Account from './pages/accounts/Account';
import ActiveDuplicates from './pages/activeDuplicates/activeDuplicates';
import ActiveDuplicatesFiles from './pages/activeDuplicates/activeDuplicatesFiles';
import PortfolioFile from './pages/portfolioFile/portfolioFile';

export const bit_pages = {
  Organizations,
  Portfolios,
  Locations,
  LocationPortfolios,
  Accounts,
  Account,
  ActiveDuplicates,
  ActiveDuplicatesFiles,
  PortfolioFile,
};

export default () => {
  return {
    bit_pages,
  };
};
