import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './activeDuplicates.module.css';
import { Box } from "@mui/material"

// API interface
import BitMgmt from "../../utils/fetch/bitmgmt/bitmgmt";

//src modules
import {
  fc_organisms,
  fc_molecules,
  fc_atoms,
} from '../../uiSrc';
import {
  setActivePage
} from '../../reducers/appSettingsSlice';
import {
  toggleSyncLocation,
  setLocationList,
  changeSyncSize,
  setViewLocation,
  updateMatchCriteria,
  updateLocationCounts,
  setLocationSyncing,
  setLocationCountLoading,
  updateSpokeAccountCount,
} from '../../reducers/activeDuplicatesSlice';

import Table from '../../organisms/activeDupsTable/activeDupsTable';
import ActiveDupsMatchModal from '../../organisms/activeDupsMatchModal/activeDupsMatchModal';

const ActiveDuplicates = ( ) => {
  const {
    Grid,
  } = fc_organisms;

  const {
    Card,
  } = fc_molecules;

  const {
    Spinner,
    Button,
    CheckableRightDisplay,
  } = fc_atoms;

  const dispatch = useDispatch();
  const bitMgmtAPI = new BitMgmt();

  //all locations available to sync
  const locationList = useSelector((state) => state.ActiveDuplicates.locationList);

  //locations selected to sync
  const syncLocations = useSelector((state) => state.ActiveDuplicates.accountSync.locations);

  const viewLocation = useSelector(state => state.ActiveDuplicates.viewLocation);

  // active page for the whole application
  const ActivePage = useSelector((state) => state.ActivePage);

  const matchToggle = useSelector(state => state.ActiveDuplicates.matchToggle);

  //pull the initial list of sync enabled locations
  useEffect(() => {
    if (ActivePage !== "active_duplicates") {
      dispatch(setActivePage("active_duplicates"));
    }
    if (!locationList.length) {
      setAllLocationsList();
    }
  },[]);

  // gets and sets all ports for selected location
  const [locationPorts,setLocationPorts] = useState([]);
  const [portsLoading,setPortsLoading] = useState(false);
  const [locationsLoading,setLocationsLoading] = useState(true);

  const [modal,setModal] = useState(false);

  const setAllLocationsList = async () => {

    setLocationsLoading(true);

    // probably move this to the fetch call or refactor eventually - KS 3/30/22
    const postbody = {};

    if((matchToggle.ssn && matchToggle.clt_ref_no) || matchToggle.both){
      postbody.ssnMatch = false;
      postbody.cltRefMatch = false;
    } else {
      postbody.ssnMatch = matchToggle.ssn;
      postbody.cltRefMatch = matchToggle.clt_ref_no;
    }
    // gets the active dup count and the FC account count
    const counts = await bitMgmtAPI.getActiveDupCounts(postbody.ssnMatch, postbody.cltRefMatch);
    dispatch(setLocationList(counts));
    setLocationsLoading(false);
  };

  const getLocationAccountCounts = (locations) => {
    const getAccountsForEachLocation = locations && locations.length ? locations.forEach((loc) => {
      // check if the location already has count loaded, this way in the 'load all' case the accounts aren't being reloaded
      const location = locationList.find(l => l.short_name === loc);
      if(location && !location.location_count){
        getLocationCount(loc);
      }
    }) : null
    return getAccountsForEachLocation;
  };

  const getLocationCount = async (loc) => {
    // adding loading indicator for user
    dispatch(setLocationCountLoading({location: loc, loading: true}));

    const accounts = await bitMgmtAPI.locationAccountNumbers(loc);
    console.log('location accounts:', accounts.location_count);

    dispatch(updateSpokeAccountCount({
      location: loc,
      location_count: accounts.location_count,
    }));
  };
  
  //deselecting all locations in remote sync list
  const deselectAllLocations = () => {
    const locations = [...locationList];
    locations.forEach((l) => {
      if (syncLocations[l.short_name]) {
        dispatch(toggleSyncLocation({short_name: l.short_name, sync_size: l.sync_size}));
      }
    });
  };

  //selecting all locations in remote sync list
  const selectAllLocations = () => {
    const locations = [...locationList];
    locations.forEach((l) => {
      if (!syncLocations[l.short_name]) {
        dispatch(toggleSyncLocation({short_name: l.short_name, sync_size: l.sync_size}));
      }
    });
  };

  const listDetails =
    syncLocations && Object.keys(syncLocations).length > 0 && Object.keys(syncLocations).length === locationList.length
    ? {display: 'Unselect All', action: () => deselectAllLocations(), listLength: Object.keys(syncLocations).length}
      : syncLocations && Object.keys(syncLocations).length
    ? {display: 'Select All', action: () => selectAllLocations(), listLength: Object.keys(syncLocations).length}
      : {display: 'Select All', action: () => selectAllLocations(), listLength: 0};

  //only updates in redux store - does not change database value currently
  const updateSyncSize = (e, loc) => {
    const newSize = e.target.value;
    dispatch(changeSyncSize({newSize: newSize, location: loc}));
  };

  //run sync for each location in accountSync list:
  const syncAllLocations = async () => {

    dispatch(setViewLocation({}));

    Object.keys(syncLocations).forEach(async(l) => {
      const {sync_size} = syncLocations[l];
      console.log(l,sync_size);

    // add loading state
    dispatch(setLocationSyncing({location: l, running: true}));

    // sync the accounts
    await bitMgmtAPI.syncAccountRecords(l,sync_size);

    // get the updated counts
    const locCounts = await bitMgmtAPI.getFCcentralAccountsByLocation(matchToggle,l);
    // console.log('updated location counts:', locCounts);
    dispatch(updateLocationCounts({
      location: l,
      fc_count: locCounts.fc_count,
      external_dups: locCounts.external_dups,
    }));
    if (syncLocations[l]) {
      dispatch(toggleSyncLocation({short_name: l, sync_size: sync_size}));
    }
    });
  };

  // sync accounts for one location only
  // eventually pass this into the forEach of the syncAllLocations function
  const runAccountsSync = async (loc) => {
    // add loading state and reset the port breakdown
    dispatch(setLocationSyncing({location: loc.short_name, running: true}));
    dispatch(setViewLocation({}));

    // sync the accounts
    await bitMgmtAPI.syncAccountRecords(loc.short_name,loc.sync_size);

    // get the updated counts
    const locCounts = await bitMgmtAPI.getFCcentralAccountsByLocation(matchToggle,loc.short_name);
    // console.log('updated location counts:', locCounts);
    dispatch(updateLocationCounts({
      location: loc.short_name,
      fc_count: locCounts.fc_count,
      external_dups: locCounts.external_dups,
    }));

    // console.log('location list:',locationList);
    // remove the location from the syncLocation object
    if (syncLocations[loc.short_name]) {
      dispatch(toggleSyncLocation({short_name: loc.short_name, sync_size: loc.sync_size}));
    }
  };

  const locationBreakdown = async (e,loc) => {
    // hack to stop the parent CheckableRightDisplay element's onClick event from firing
    e.stopPropagation();
    dispatch(setViewLocation(loc));
    setPortsLoading(true);

    const postbody = {};
    if((matchToggle.ssn && matchToggle.clt_ref_no) || matchToggle.both){
      postbody.ssnMatch = false;
      postbody.cltRefMatch = false;
    } else {
      postbody.ssnMatch = matchToggle.ssn;
      postbody.cltRefMatch = matchToggle.clt_ref_no;
    }

    // get the active duplicates in the fullcircle system
    const FCcounts = await bitMgmtAPI.getPortDupCount(loc.short_name, postbody.ssnMatch, postbody.cltRefMatch);

    // get the number of accounts at each port from the spoke
    const spokes = await bitMgmtAPI.getSpokePortCountsByLocation(loc.short_name);

    // 
    const reducedData = FCcounts.reduce((prev,curr) => {
      const tmp = {...prev};
      tmp[curr.clt_id] = tmp[curr.clt_id] ? tmp[curr.clt_id].external_dups : []
      tmp[curr.clt_id].push(curr);
      return tmp;
    }, {});
    const portWithDupsMap = spokes.map((i) => {
      i.port_dups = reducedData[i.clt_id] ? reducedData[i.clt_id][0].external_dups : 0;
      return i;
    });

    setLocationPorts(portWithDupsMap);

    setPortsLoading(false);
  };

  const [portsTableSize,setPortsTableSize] = useState(20);
  const [locationTableSize,setLocationTableSize] = useState(20);
  const [portsTableIndex,setPortsTableIndex] = useState(0);
  const [locationTableIndex,setLocationTableIndex] = useState(0);

  return(
      <Box sx={{ marginTop: {xs: "8vh", md: "4vh"}}}>
      <Grid gridSize={'two'}>
        <Card
          title="Active Duplicates"
          topRight={
            <div>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => syncAllLocations()}
                disabled={listDetails.listLength === 0 ? true : false}
              >
                {`Sync All (${listDetails.listLength})`}
              </Button>

              <Button
                onClick={() => setModal('matchCriteria')}
              >
                Criteria
              </Button>
            </div>

          }
        >
        {locationsLoading ? 
          <div
            className="inline_center"
            style={{ alignItems: 'center' }}
          >
            <Spinner />
          </div>
          :
          <div style={{ width: '100%' }}>
            <div
              className={styles.header}
              style={{justifyContent: 'space-between'}}
            >
              <span
                style={{}}
                onClick={listDetails.action}
              >
                {listDetails.display}
              </span>
              <span
                style={{marginRight: 20}}
              >
                Accounts
              </span>
              <span>
                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => getLocationAccountCounts(Object.keys(syncLocations))}
                  disabled={listDetails.listLength === 0 ? true : false}
                >
                  {`Load All (${listDetails.listLength})`}
                </Button>
              </span>
            </div>
            <Table
              pageIndexProp={locationTableIndex}
              pageSizeProp={locationTableSize}
              setPageSizeProp={setLocationTableSize}
              setPageIndexProp={setLocationTableIndex}
              columns={[
              {
                Header: 'short_name',
                accessor: 'short_name',
                customHeader: 'Location',
                Cell: ({row}) => {
                  const {original} = row;
                  return (
                    <CheckableRightDisplay
                      key={original.id}
                      style={{marginBottom: 0}}
                      onClick={() => dispatch(toggleSyncLocation({short_name: original.short_name, sync_size: original.sync_size}))}
                      checked={syncLocations[original.short_name]}
                      rightLabel={
                        <span
                          onClick={(e) => locationBreakdown(e,original)}
                          style={{textDecoration: 'underline', cursor:'pointer'}}
                        >
                          {original.short_name}
                        </span>
                      }
                    />
                  )}
                },
                {
                  Header: 'external_dups',
                  accessor: 'external_dups',
                  customHeader: 'Active Dupes',
                },
                {
                  Header: 'fc_count',
                  accessor: 'fc_count',
                  customHeader: 'FullCircle',
                },
                {
                  Header: 'location_count',
                  accessor: 'location_count',
                  customHeader: 'Location',
                  Cell: ({row}) => {
                    const {original} = row;
                    return (
                      <span>
                        { 
                        original.loadingCount
                        ? <Spinner />
                        : original.location_count
                        ? original.location_count :
                        <Button
                          onClick={() => getLocationAccountCounts([original.short_name])}
                        >
                          Load
                        </Button>
                        }
                      </span>
                  )}
                },
                {
                  Header: 'sync_size',
                  accessor: 'sync_size',
                  customHeader: 'Sync Size',
                  Cell: ({row}) => {
                    const {original} = row;
                    return (
                      <input
                        type={'number'}
                        value={original.sync_size}
                        className={styles.input}
                        onChange={(e) => updateSyncSize(e, original.short_name)}
                        style={{width: 90}}
                      />
                  )}
                },
                {
                  Header: '',
                  accessor: 'sync',
                  disableSortBy: true,
                  Cell: ({row}) => {
                    const {original} = row;
                    return (
                      <Button
                        style={{ marginRight: 10 }}
                        onClick={() => runAccountsSync(original)}
                        disabled={original.syncing ? true : false}
                      >
                        {
                          original.syncing
                          ? <Spinner />
                          : `Sync`
                        }
                      </Button>
                  )}
                },
              ]}
              data={locationList}
            />
          </div>
        }
        </Card>
        <Card
          title={ locationsLoading
            ? ''
            : viewLocation.short_name
            ? `Location: ${viewLocation.short_name}`
            : `Select a location...`
          }
        >
          <div style={{ width: '100%' }}>
            { viewLocation.short_name ?
              <div>
                { 
                portsLoading ? 
                  <div
                      className="inline_center"
                      style={{ alignItems: 'center', paddingTop: 10 }}
                    >
                      <Spinner />
                  </div>
                : 
                  <Table
                    pageIndexProp={portsTableIndex}
                    pageSizeProp={portsTableSize}
                    setPageSizeProp={setPortsTableSize}
                    setPageIndexProp={setPortsTableIndex}
                    columns={[
                      {
                        Header: 'clt_id',
                        accessor: 'clt_id',
                        customHeader: 'Portfolio',
                      },
                      {
                        Header: 'port_dups',
                        accessor: 'port_dups',
                        customHeader: 'Active Dupes',
                      },
                      {
                        Header: 'total_count',
                        accessor: 'total_count',
                        customHeader: 'Count',
                      },
                      {
                        Header: 'percent',
                        accessor: 'percent',
                        customHeader: 'AD %',
                        Cell: ({row}) => {
                          const {original} = row;
                          return (
                            <span>
                              {original.port_dups && original.total_count ? `${((original.port_dups / original.total_count) * 100).toFixed(2)}%` : '0%'}
                            </span>
                        )}
                      },
                    ]}
                    data={locationPorts}
                  />
                }
              </div>
            : locationsLoading
              ? null
            : null
            }
          </div>
        </Card>
      </Grid>
      <ActiveDupsMatchModal
        modal={modal}
        setModal={setModal}
        sumbitChanges={() => {
          setAllLocationsList();
          setLocationPorts([]);
          dispatch(setViewLocation({}));
        }}
        matchToggle={matchToggle}
        updateMatchCriteria={(opts) => dispatch(updateMatchCriteria(opts))}
      />
    </Box>
  );
};

export default ActiveDuplicates;
